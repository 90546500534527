class window.Payslip

  @current_day = 0

  @init: ->
    $('.attendance').on('click', @open_modal)
    $('#attendance-submit').on('click', @update_attendance)
    $('.attendance-option').on('click', @toggle_attendance_time_changers)
    $('.modify').on('click', @toggle_form)
    $('#payslip_pay_in_advance_tfr_amount').on('keyup change', @update_payslip)
    $('.form-submit').on('click', @execute_action)
    $('.attendance-option').on 'click', @update_rnb
    $('.new-modal').on 'click', @open_new_modal
    $('#new-payslip-modal.reveal-modal select#payslip_year').on 'change', @reset_availabile_months

  @open_new_modal: (evt) ->
    evt.preventDefault() if !!evt
    $('#new-payslip-modal').foundation('reveal', 'open')
    Payslip.reset_availabile_months()
    

  @reset_availabile_months: ->
    modal = $('#new-payslip-modal.reveal-modal')
    two_months_from_now = Utils.two_months_from_now()
    year  = modal.find('#payslip_year').val()
    modal.find('#payslip_month').find('option').each((index,option) ->
      month = $(option).attr('value')
      if new Date(year, month, 1) < two_months_from_now
        $(option).removeAttr('disabled')      # enable
      else
        $(option).attr("disabled","disabled") # disable
        $(option).removeAttr("selected")
    )

  @execute_action: (e) ->
    e.preventDefault()
    url = $(this).attr('href')
    $('form.edit_payslip').attr('action', url)
    $( "input[name='_method']" ).val('post')
    $('form.edit_payslip').submit()

  @open_modal: ->
    $('#day-title').html($(this).data('title'))
    
    Payslip.minutes = $(this).data('workedminutes')/60.0
    Payslip.bankholiday = $(this).data('bankholiday')
    text = if Payslip.minutes == 1 then ' ora da contratto' else ' ore da contratto'

    if Payslip.bankholiday == 0
      $('#default-attendance-text').html( Payslip.minutes + text)
      $('#attendance_day_off').prop('disabled', false)
      $('#attendance_mandatory_pregnancy').prop('disabled', false)
      $('#attendance_no_paid_absence').prop('disabled', false)
    else
      $('#default-attendance-text').html( 'Festività (' + Payslip.minutes + text + ')')
      $('#attendance_day_off').prop('disabled', 'disabled')
      $('#attendance_mandatory_pregnancy').prop('disabled', false)
      $('#attendance_no_paid_absence').prop('disabled', false)

    Payslip.current_day = $(this).data('day')

    checked = '#attendance_default'
    checked = '#attendance_sick_day' if $('#payslip_sick_days_'+$(this).data('day')).val() == '1'
    checked = '#attendance_day_off' if $('#payslip_days_off_'+$(this).data('day')).val() == '1'
    checked = '#attendance_mandatory_pregnancy' if $('#payslip_absences_'+$(this).data('day')).val() == 'MP'
    checked = '#attendance_no_paid_absence' if $('#payslip_absences_'+$(this).data('day')).val() == 'NPA'
    $(checked).prop("checked", true)

    if $('#payslip_not_rnb_benefits_'+$(this).data('day')).val() == '1'
      $('#not_rnb_benefit').prop("checked", true)
    else  
      $('#not_rnb_benefit').prop("checked", false)

    Payslip.disable_attendance_time_changers(checked != '#attendance_default')

    Payslip.set_minutes('overtime', $('#payslip_overtime_'+$(this).data('day')).val())
    Payslip.set_minutes('night_overtime', $('#payslip_night_overtime_'+$(this).data('day')).val())
    Payslip.set_minutes('time_off', $('#payslip_time_off_'+$(this).data('day')).val())

    $('#attendance-modal').foundation('reveal', 'open')

  @update_attendance: ->
    sick_day = 0
    day_off = 0
    overtime = 0
    night_overtime = 0
    time_off = 0
    absence = ''
    symbol = ''

    cause = $("#attendance-modal input[type='radio']:checked").val()
    overtime = Payslip.get_minutes('overtime')
    night_overtime = Payslip.get_minutes('night_overtime')
    time_off = Payslip.get_minutes('time_off')

    if cause == 'sick_day'
      sick_day = 1
      symbol = '<i class="fa fa-plus-circle"></i>'
      overtime = 0
      night_overtime = 0
      time_off = 0
      absence = ''
    else if cause == 'day_off'
      day_off = 1
      symbol = '<i class="far fa-circle"></i>'
      overtime = 0
      night_overtime = 0
      time_off = 0
      absence = ''
    else if cause == 'mandatory_pregnancy'
      absence = 'MP'
      symbol = '<i class="far fa-pause-circle"></i>'
      overtime = 0
      night_overtime = 0
      time_off = 0
      day_off = 0
    else if cause == 'no_paid_absence'
      absence = 'NPA'
      symbol = '<i class="fa fa-minus-circle"></i>'
      overtime = 0
      night_overtime = 0
      time_off = 0
      day_off = 0
    else if cause == 'default' and Payslip.minutes > 0
      if Payslip.bankholiday == 0
        symbol = '<i class="fa fa-check-circle"></i>'
      else
        symbol = '<i class="far fa-check-circle"></i>'

    if overtime != 0 or night_overtime != 0 or time_off != 0
      symbol = '<i class="fa fa-dot-circle"></i>'

    $('#payslip_sick_days_'+Payslip.current_day).val(sick_day)
    $('#payslip_days_off_'+Payslip.current_day).val(day_off)
    $('#payslip_overtime_'+Payslip.current_day).val(overtime)
    $('#payslip_night_overtime_'+Payslip.current_day).val(night_overtime)
    $('#payslip_time_off_'+Payslip.current_day).val(time_off)
    $('#payslip_absences_'+Payslip.current_day).val(absence)
    $('#span-day-'+Payslip.current_day).html(symbol)

    if $("#not_rnb_benefit").is(':checked')
      $('#payslip_not_rnb_benefits_'+Payslip.current_day).val('1')
      $('#span-number-'+Payslip.current_day).addClass('red bold')
    else
      $('#payslip_not_rnb_benefits_'+Payslip.current_day).val('0')
      $('#span-number-'+Payslip.current_day).removeClass('red bold')

    $('#attendance-modal').foundation('reveal', 'close')
    Payslip.update_payslip()

  @toggle_attendance_time_changers: ->
    Payslip.disable_attendance_time_changers($(this).val() != 'default')

  @toggle_time_off: ->
    state = if Payslip.minutes == 0 or Payslip.bankholiday == 1 then 'disabled' else false
    $('.attendance-time-changer.time-off').prop('disabled', state)

  @disable_attendance_time_changers: (disable)->
    if disable == true
      $('.attendance-time-changer').prop('disabled', 'disabled')
    else
      $('.attendance-time-changer').prop('disabled', false)
      Payslip.toggle_time_off()

  @get_minutes: (id) ->
    hrs = $('#'+id+'_hrs').val()
    mins = $('#'+id+'_mins').val()
    return parseInt(hrs) + parseInt(mins)

  @set_minutes: (id, time) ->
    mins = time%60
    hrs = time - mins
    $('#'+id+'_hrs').val(hrs)
    $('#'+id+'_mins').val(mins)

  @update_payslip: ->
    console.log($('form.edit_payslip').serialize())
    valuesToSubmit = $('form.edit_payslip').serialize().replace('_method=patch', '_method=put')
    #console.log valuesToSubmit
    Payslip.refresh_data('<i class="fa fa-refresh fa-spin"></i>',
                         '<i class="fa fa-refresh fa-spin"></i>',
                         '<i class="fa fa-refresh fa-spin"></i>',
                         '<i class="fa fa-refresh fa-spin"></i>',
                         '<i class="fa fa-refresh fa-spin"></i>',
                         '<i class="fa fa-refresh fa-spin"></i>')

    response = $.ajax("/payslips/ajax_update_amounts",
      method: 'POST',
      data: valuesToSubmit,
      dataType: "json")

    response.fail (data) ->
      Payslip.refresh_data('0,00', '0,00', '0,00', '0,00', '0,00', 'Si è verificato un errore')
    response.done (data) ->
      Payslip.refresh_data(data.total_amount,
                           data.inps,
                           data.cassa_colf,
                           data.tfr,
                           data.thirteen,
                           data.payslip_items)

  @refresh_data: (total_amount, inps, cassa_colf, tfr, thirteen, payslip_items) ->
    $('#payslip_total_amount').html(total_amount)
    $('#inps').html(inps)
    $('#cassa-colf').html(cassa_colf)
    $('#tfr').html(tfr)
    $('#thirteen').html(thirteen)
    $('#payslip-items').html(payslip_items)

  @toggle_form: ->
    type = $(this).data('type')
    $('#detail-'+type).toggle()
    $('#form-'+type).toggle()

  @update_rnb: ->
    if ($("#not_rnb_benefit").length)
      if ($(this).val() == 'day_off' || $(this).val() == 'sick_day')
        $("#not_rnb_benefit").prop('checked', true)
      else
        $("#not_rnb_benefit").prop('checked', false)
      

$ ->
  if Page.is('.payslips')
    Payslip.init()
