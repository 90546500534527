class window.Subscription

  @current_day = 0

  @init: ->
    $(document).on('mouseleave', @open_modal)
    $('#logo').on('mouseover', @open_modal)
    $('.side-nav li').on('mouseover', @open_modal)
    $('#stripe-checkout').on('click', @stripe_checkout)
    $('#stripe-setup').on('click', @stripe_checkout_setup)

  @open_modal: ->
    unless Cookies.get('feedback-trial-expired-given')
      $('#feedback-modal').foundation('reveal', 'open')

  @stripe_checkout: ->
    stripe_session = $.ajax("/subscription/stripe_create_checkout_session.json", 
      method: 'GET')
    stripe_session.fail (data) ->
      alert('Si è verificato un errore [stripe session]')
    stripe_session.done (data) ->
      stripe = Stripe($('#stripe-checkout').data('key'))
      stripe.redirectToCheckout({sessionId: data.sessionId})

  @stripe_checkout_setup: ->
    stripe_session = $.ajax("/subscription/stripe_create_checkout_session.json?setup=true", 
      method: 'GET')
    stripe_session.fail (data) ->
      alert('Si è verificato un errore [stripe session]')
    stripe_session.done (data) ->
      stripe = Stripe($('#stripe-setup').data('key'))
      stripe.redirectToCheckout({sessionId: data.sessionId})

$ ->
  if Page.is('.subscriptions')
    Subscription.init()
