/*
File generated by js-routes 1.4.1
Based on Rails routes of Colfnoproblem::Application
 */

(function() {
  var DeprecatedBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, Utils, root,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  root = typeof exports !== "undefined" && exports !== null ? exports : this;

  ParameterMissing = function(message) {
    this.message = message;
  };

  ParameterMissing.prototype = new Error();

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  SpecialOptionsKey = "_options";

  DeprecatedBehavior = false;

  ReservedOptions = ['anchor', 'trailing_slash', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = DeprecatedBehavior || actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          if (value != null) {
            delete parameters[left];
            return this.path_identifier(value);
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      if (left.replace(/^\*/i, "") !== left) {
        route[1] = left = left.replace(/^\*/i, "");
      }
      value = parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      parameters[left] = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      return this.visit(route, parameters, optional);
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (root.jQuery && (root.jQuery.type != null)) {
        return root.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace.split(".");
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// account => /account(.:format)
  // function(options)
  account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin => /admin(.:format)
  // function(options)
  admin_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_account => /admin/accounts/:id(.:format)
  // function(id, options)
  admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_accounts => /admin/accounts(.:format)
  // function(options)
  admin_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_contribution_rate => /admin/contribution_rates/:id(.:format)
  // function(id, options)
  admin_contribution_rate_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"contribution_rates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_contribution_rates => /admin/contribution_rates(.:format)
  // function(options)
  admin_contribution_rates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"contribution_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_dashboard => /admin/dashboard(.:format)
  // function(options)
  admin_dashboard_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"dashboard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_email => /admin/emails/:id(.:format)
  // function(id, options)
  admin_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_emails => /admin/emails(.:format)
  // function(options)
  admin_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_feedback => /admin/feedback(.:format)
  // function(options)
  admin_feedback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"feedback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_message => /admin/messages/:id(.:format)
  // function(id, options)
  admin_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_messages => /admin/messages(.:format)
  // function(options)
  admin_messages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"messages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_minimum_wage => /admin/minimum_wages/:id(.:format)
  // function(id, options)
  admin_minimum_wage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minimum_wages",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_minimum_wages => /admin/minimum_wages(.:format)
  // function(options)
  admin_minimum_wages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minimum_wages",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_plan => /admin/plans/:id(.:format)
  // function(id, options)
  admin_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_plans => /admin/plans(.:format)
  // function(options)
  admin_plans_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_room_and_board_compensation => /admin/room_and_board_compensations/:id(.:format)
  // function(id, options)
  admin_room_and_board_compensation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"room_and_board_compensations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_room_and_board_compensations => /admin/room_and_board_compensations(.:format)
  // function(options)
  admin_room_and_board_compensations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"room_and_board_compensations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_sidekiq => /admin/sidekiq
  // function(options)
  admin_sidekiq_path: Utils.route([], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[6,"sidekiq",false]]]]),
// admin_smse => /admin/smses/:id(.:format)
  // function(id, options)
  admin_smse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"smses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_smses => /admin/smses(.:format)
  // function(options)
  admin_smses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"smses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_stripe_event => /admin/stripe_events/:id(.:format)
  // function(id, options)
  admin_stripe_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stripe_events",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_stripe_events => /admin/stripe_events(.:format)
  // function(options)
  admin_stripe_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stripe_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_test_exception => /admin/test_exception(.:format)
  // function(options)
  admin_test_exception_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"test_exception",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// after_signup => /after_signup/:id(.:format)
  // function(id, options)
  after_signup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"after_signup",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// after_signup_index => /after_signup(.:format)
  // function(options)
  after_signup_index_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"after_signup",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// ajax_update_amounts_payslips => /payslips/ajax_update_amounts(.:format)
  // function(options)
  ajax_update_amounts_payslips_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[6,"ajax_update_amounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// ajax_update_economics_employees => /employees/ajax_update_economics(.:format)
  // function(options)
  ajax_update_economics_employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"ajax_update_economics",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// browser_not_supported => /browser_not_supported(.:format)
  // function(options)
  browser_not_supported_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"browser_not_supported",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bulk_destroy_admin_contribution_rates => /admin/contribution_rates/bulk_destroy(.:format)
  // function(options)
  bulk_destroy_admin_contribution_rates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"contribution_rates",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_destroy_admin_minimum_wages => /admin/minimum_wages/bulk_destroy(.:format)
  // function(options)
  bulk_destroy_admin_minimum_wages_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minimum_wages",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_destroy_admin_room_and_board_compensations => /admin/room_and_board_compensations/bulk_destroy(.:format)
  // function(options)
  bulk_destroy_admin_room_and_board_compensations_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"room_and_board_compensations",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_destroy_payslips => /payslips/bulk_destroy(.:format)
  // function(options)
  bulk_destroy_payslips_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[6,"bulk_destroy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// bulk_resend_confirmation_admin_accounts => /admin/accounts/bulk_resend_confirmation(.:format)
  // function(options)
  bulk_resend_confirmation_admin_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"bulk_resend_confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// bulk_update_status_admin_accounts => /admin/accounts/bulk_update_status(.:format)
  // function(options)
  bulk_update_status_admin_accounts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"bulk_update_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_user_registration => /users/cancel(.:format)
  // function(options)
  cancel_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// checking_subscription => /subscription/checking/:status(.:format)
  // function(status, options)
  checking_subscription_path: Utils.route([["status",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"checking",false],[2,[7,"/",false],[2,[3,"status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// contacts => /contatti(.:format)
  // function(options)
  contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contatti",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// create_contacts => /contatti(.:format)
  // function(options)
  create_contacts_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contatti",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// deliver_public_template => /modulistica(/:id)/deliver(.:format)
  // function(options)
  deliver_public_template_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"modulistica",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[2,[7,"/",false],[2,[6,"deliver",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// destroy_user_session => /users/sign_out(.:format)
  // function(options)
  destroy_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_account => /account/edit(.:format)
  // function(options)
  edit_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_admin_account => /admin/accounts/:id/edit(.:format)
  // function(id, options)
  edit_admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_contribution_rate => /admin/contribution_rates/:id/edit(.:format)
  // function(id, options)
  edit_admin_contribution_rate_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"contribution_rates",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_email => /admin/emails/:id/edit(.:format)
  // function(id, options)
  edit_admin_email_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_message => /admin/messages/:id/edit(.:format)
  // function(id, options)
  edit_admin_message_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_minimum_wage => /admin/minimum_wages/:id/edit(.:format)
  // function(id, options)
  edit_admin_minimum_wage_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minimum_wages",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_plan => /admin/plans/:id/edit(.:format)
  // function(id, options)
  edit_admin_plan_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_room_and_board_compensation => /admin/room_and_board_compensations/:id/edit(.:format)
  // function(id, options)
  edit_admin_room_and_board_compensation_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"room_and_board_compensations",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_smse => /admin/smses/:id/edit(.:format)
  // function(id, options)
  edit_admin_smse_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"smses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_stripe_event => /admin/stripe_events/:id/edit(.:format)
  // function(id, options)
  edit_admin_stripe_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stripe_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_after_signup => /after_signup/:id/edit(.:format)
  // function(id, options)
  edit_after_signup_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"after_signup",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_employee => /employees/:id/edit(.:format)
  // function(id, options)
  edit_employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_invoice => /invoices/:id/edit(.:format)
  // function(id, options)
  edit_invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_payslip => /payslips/:id/edit(.:format)
  // function(id, options)
  edit_payslip_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_quarter_contribution => /quarter_contributions/:id/edit(.:format)
  // function(id, options)
  edit_quarter_contribution_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"quarter_contributions",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_settings => /settings/edit(.:format)
  // function(options)
  edit_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_subscription => /subscription/edit(.:format)
  // function(options)
  edit_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// edit_template_account => /account/modulistica(/:employee_id)(/:template_id)/edit(.:format)
  // function(options)
  edit_template_account_path: Utils.route([["employee_id",false],["template_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"modulistica",false],[2,[1,[2,[7,"/",false],[3,"employee_id",false]],false],[2,[1,[2,[7,"/",false],[3,"template_id",false]],false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /users/password/edit(.:format)
  // function(options)
  edit_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// edit_user_registration => /users/edit(.:format)
  // function(options)
  edit_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// email_subscription_settings => /settings/email_subscription(/:token)(.:format)
  // function(options)
  email_subscription_settings_path: Utils.route([["token",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"email_subscription",false],[2,[1,[2,[7,"/",false],[3,"token",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// email_subscription_update_settings => /settings/email_subscription_update(/:token)(.:format)
  // function(options)
  email_subscription_update_settings_path: Utils.route([["token",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"email_subscription_update",false],[2,[1,[2,[7,"/",false],[3,"token",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// employee => /employees/:id(.:format)
  // function(id, options)
  employee_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// employees => /employees(.:format)
  // function(options)
  employees_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// inbound_admin_smses => /admin/smses/inbound(.:format)
  // function(options)
  inbound_admin_smses_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"smses",false],[2,[7,"/",false],[2,[6,"inbound",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// integrations_stripe_events => /integrations/stripe_events(.:format)
  // function(options)
  integrations_stripe_events_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"integrations",false],[2,[7,"/",false],[2,[6,"stripe_events",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// invoice => /invoices/:id(.:format)
  // function(id, options)
  invoice_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// invoices => /invoices(.:format)
  // function(options)
  invoices_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invoices",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// login_admin_account => /admin/accounts/:id/login(.:format)
  // function(id, options)
  login_admin_account_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"login",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_account => /account/new(.:format)
  // function(options)
  new_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_account => /admin/accounts/new(.:format)
  // function(options)
  new_admin_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"accounts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_contribution_rate => /admin/contribution_rates/new(.:format)
  // function(options)
  new_admin_contribution_rate_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"contribution_rates",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_email => /admin/emails/new(.:format)
  // function(options)
  new_admin_email_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_message => /admin/messages/new(.:format)
  // function(options)
  new_admin_message_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"messages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_minimum_wage => /admin/minimum_wages/new(.:format)
  // function(options)
  new_admin_minimum_wage_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"minimum_wages",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_plan => /admin/plans/new(.:format)
  // function(options)
  new_admin_plan_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_room_and_board_compensation => /admin/room_and_board_compensations/new(.:format)
  // function(options)
  new_admin_room_and_board_compensation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"room_and_board_compensations",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_smse => /admin/smses/new(.:format)
  // function(options)
  new_admin_smse_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"smses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_stripe_event => /admin/stripe_events/new(.:format)
  // function(options)
  new_admin_stripe_event_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stripe_events",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_after_signup => /after_signup/new(.:format)
  // function(options)
  new_after_signup_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"after_signup",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_employee => /employees/new(.:format)
  // function(options)
  new_employee_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"employees",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_invoice => /invoices/new(.:format)
  // function(options)
  new_invoice_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"invoices",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_payslip => /payslips/new(.:format)
  // function(options)
  new_payslip_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_quarter_contribution => /quarter_contributions/new(.:format)
  // function(options)
  new_quarter_contribution_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"quarter_contributions",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_settings => /settings/new(.:format)
  // function(options)
  new_settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_subscription => /subscription/new(.:format)
  // function(options)
  new_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_confirmation => /users/confirmation/new(.:format)
  // function(options)
  new_user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_password => /users/password/new(.:format)
  // function(options)
  new_user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_registration => /users/sign_up(.:format)
  // function(options)
  new_user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_user_session => /users/sign_in(.:format)
  // function(options)
  new_user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// overview_account => /account/overview(.:format)
  // function(options)
  overview_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"overview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payslip => /payslips/:id(.:format)
  // function(id, options)
  payslip_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// payslips => /payslips(.:format)
  // function(options)
  payslips_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// pdf_footer => /pdf/footer(.:format)
  // function(options)
  pdf_footer_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pdf",false],[2,[7,"/",false],[2,[6,"footer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// pdf_guida_sms => /pdf/guida_sms(.:format)
  // function(options)
  pdf_guida_sms_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"pdf",false],[2,[7,"/",false],[2,[6,"guida_sms",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// print_description_payslip => /payslips/:id/print_description(.:format)
  // function(id, options)
  print_description_payslip_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"print_description",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// print_payslip => /payslips/:id/print(.:format)
  // function(id, options)
  print_payslip_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"print",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// process_event_admin_stripe_event => /admin/stripe_events/:id/process_event(.:format)
  // function(id, options)
  process_event_admin_stripe_event_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"stripe_events",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"process_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// public_template => /modulistica(/:id)(.:format)
  // function(options)
  public_template_path: Utils.route([["id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"modulistica",false],[2,[1,[2,[7,"/",false],[3,"id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]),
// public_templates => /modulistica(.:format)
  // function(options)
  public_templates_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"modulistica",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// quarter_contribution => /quarter_contributions/:id(.:format)
  // function(id, options)
  quarter_contribution_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"quarter_contributions",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// quarter_contributions => /quarter_contributions(.:format)
  // function(options)
  quarter_contributions_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"quarter_contributions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// queue_status => /queue-status(.:format)
  // function(options)
  queue_status_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"queue-status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// rails_blob_representation => /rails/active_storage/representations/:signed_blob_id/:variation_key/*filename(.:format)
  // function(signed_blob_id, variation_key, filename, options)
  rails_blob_representation_path: Utils.route([["signed_blob_id",true],["variation_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"representations",false],[2,[7,"/",false],[2,[3,"signed_blob_id",false],[2,[7,"/",false],[2,[3,"variation_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]]]),
// rails_direct_uploads => /rails/active_storage/direct_uploads(.:format)
  // function(options)
  rails_direct_uploads_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"direct_uploads",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_disk_service => /rails/active_storage/disk/:encoded_key/*filename(.:format)
  // function(encoded_key, filename, options)
  rails_disk_service_path: Utils.route([["encoded_key",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_key",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// rails_info => /rails/info(.:format)
  // function(options)
  rails_info_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_info_properties => /rails/info/properties(.:format)
  // function(options)
  rails_info_properties_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"properties",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_info_routes => /rails/info/routes(.:format)
  // function(options)
  rails_info_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"info",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// rails_mailers => /rails/mailers(.:format)
  // function(options)
  rails_mailers_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"mailers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_routes => /rails/routes(.:format)
  // function(options)
  rails_routes_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"routes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// rails_service_blob => /rails/active_storage/blobs/:signed_id/*filename(.:format)
  // function(signed_id, filename, options)
  rails_service_blob_path: Utils.route([["signed_id",true],["filename",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"blobs",false],[2,[7,"/",false],[2,[3,"signed_id",false],[2,[7,"/",false],[2,[5,[3,"*filename",false],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]]]),
// reactivate_subscription => /subscription/reactivate(.:format)
  // function(options)
  reactivate_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"reactivate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// root => /
  // function(options)
  root_path: Utils.route([], {}, [7,"/",false]),
// send_mail_account => /account/send_mail(.:format)
  // function(options)
  send_mail_account_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"send_mail",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// send_test_admin_emails => /admin/emails/send_test(.:format)
  // function(options)
  send_test_admin_emails_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"emails",false],[2,[7,"/",false],[2,[6,"send_test",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_to_employee_payslip => /payslips/:id/send_to_employee(.:format)
  // function(id, options)
  send_to_employee_payslip_path: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"payslips",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_to_employee",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_wrong_payslip_feedback => /feedback/send_wrong_payslip/:token(.:format)
  // function(token, options)
  send_wrong_payslip_feedback_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"feedback",false],[2,[7,"/",false],[2,[6,"send_wrong_payslip",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// settings => /settings(.:format)
  // function(options)
  settings_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sextant_engine => /sextant
  // function(options)
  sextant_engine_path: Utils.route([], {}, [2,[7,"/",false],[6,"sextant",false]]),
// stripe_create_checkout_session_subscription => /subscription/stripe_create_checkout_session(.:format)
  // function(options)
  stripe_create_checkout_session_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"stripe_create_checkout_session",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// subscription => /subscription(.:format)
  // function(options)
  subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// template_account => /account/modulistica(/:employee_id)(/:template_id)(.:format)
  // function(options)
  template_account_path: Utils.route([["employee_id",false],["template_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"modulistica",false],[2,[1,[2,[7,"/",false],[3,"employee_id",false]],false],[2,[1,[2,[7,"/",false],[3,"template_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// templates_account => /account/modulistica(/:employee_id)(.:format)
  // function(options)
  templates_account_path: Utils.route([["employee_id",false],["format",false]], {}, [2,[7,"/",false],[2,[6,"account",false],[2,[7,"/",false],[2,[6,"modulistica",false],[2,[1,[2,[7,"/",false],[3,"employee_id",false]],false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]),
// thanks_feedback => /feedback/thanks(.:format)
  // function(options)
  thanks_feedback_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"feedback",false],[2,[7,"/",false],[2,[6,"thanks",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// trial_expired_feedback => /feedback/trial_expired/:token(.:format)
  // function(token, options)
  trial_expired_feedback_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"feedback",false],[2,[7,"/",false],[2,[6,"trial_expired",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_card_subscription => /subscription/update_card(.:format)
  // function(options)
  update_card_subscription_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"subscription",false],[2,[7,"/",false],[2,[6,"update_card",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// update_rails_disk_service => /rails/active_storage/disk/:encoded_token(.:format)
  // function(encoded_token, options)
  update_rails_disk_service_path: Utils.route([["encoded_token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"rails",false],[2,[7,"/",false],[2,[6,"active_storage",false],[2,[7,"/",false],[2,[6,"disk",false],[2,[7,"/",false],[2,[3,"encoded_token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_confirmation => /users/confirmation(.:format)
  // function(options)
  user_confirmation_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_password => /users/password(.:format)
  // function(options)
  user_password_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_registration => /users(.:format)
  // function(options)
  user_registration_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /users/sign_in(.:format)
  // function(options)
  user_session_path: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// wrong_payslip_feedback => /feedback/wrong_payslip/:token(.:format)
  // function(token, options)
  wrong_payslip_feedback_path: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"feedback",false],[2,[7,"/",false],[2,[6,"wrong_payslip",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      Object.defineProperty(routes, 'defaults', {
        get: function() {
          throw new Error("Routes" + ".defaults is removed. Use " + "Routes" + ".configure() instead.");
        },
        set: function(value) {}
      });
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Utils.namespace(root, "Routes", routes);
    }
  };

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return Utils.make();
    });
  } else {
    Utils.make();
  }

}).call(this);
