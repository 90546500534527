class window.Reveal
  @registerEvents: ->
    # custom links to close the modal
    $(document).on 'click', '.custom-close-reveal-modal', @closeModal

    $('.reveal-modal').on 'opened.fndtn.reveal', =>
      @lockBodyScrolling()
      @reapplyJsEnhancements()

    $(document).on 'modal_refreshed', @reapplyJsEnhancements

    $('.reveal-modal').on 'close.fndtn.reveal',  @unlockBodyScrolling
    $('.reveal-modal').on 'closed.fndtn.reveal', @unlockBodyScrolling

  @reapplyJsEnhancements: ->
    # every time we load a modal in ajax we need to re-initalize the new DOM elements


  @closeModal: (evt) ->
    evt.preventDefault() if !!evt
    $('.reveal-modal:visible').foundation('reveal', 'close')

  @lockBodyScrolling: ->
    $('body').addClass('modal_open')

  @unlockBodyScrolling: ->
    $('body').removeClass('modal_open')

$ ->
  Reveal.registerEvents()
