class window.Utils
  # fetches a javascript file asyncronously
  @getScript: (url, callback) ->
    script = document.createElement("script")
    script.type  = "text/javascript"
    script.async = true
    if (callback)
      script.onload = callback
    document.getElementsByTagName("head")[0].appendChild(script)
    script.src = url

  @next_month: ->
    now = new Date()
    if now.getMonth() == 11
      next_month = new Date(now.getFullYear() + 1, 0, 1)
    else
      next_month = new Date(now.getFullYear(), now.getMonth() + 1, 1)

    return next_month

  @two_months_from_now: ->
    now = new Date()
    if now.getMonth() == 10
      month = new Date(now.getFullYear() + 1, 0, 1)
    else if now.getMonth() == 11
      month = new Date(now.getFullYear() + 1, 1, 1)
    else
      month = new Date(now.getFullYear(), now.getMonth() + 2, 1)

    return month
