/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "jquery"

import "js-cookie";
import "foundation-sites"
import "foundation-sites/js/foundation/foundation.reveal"
import "foundation-sites/js/foundation/foundation.equalizer"

import "../vendor/jquery.cookiebar"

import "../base/alerts_replacement"
import "../base/background_height"
import "../base/datepicker"
import "../base/nav"
import "../base/page"
import "../base/reveal"
import "../base/sidebar"
import "../base/tabs"
import "../base/toolbar"
import "../base/utils"

import "../gtm"
import "../employee_economics"
import "../contribution_rate"
import "../payslip"
import "../optional_address"
import "../contact"
import "../subscription"

import "../routes"

$(function(){ 
  $(document).foundation({equalizer : {
    // Specify if Equalizer should make elements equal height once they become stacked.
    equalize_on_stack: true,
    // Allow equalizer to resize hidden elements
    act_on_hidden_el: false
  }});

  var domain = String(window.location.hostname).split('.');
  domain.shift();
  domain = domain.join('.');

  $.cookieBar({
    message: 'Questo sito o gli strumenti terzi da questo utilizzati si avvalgono di cookie',
    acceptText: 'Chiudi',
    acceptFunction: null,
    declineButton: false,
    declineText: 'Disable Cookies',
    declineFunction: null,
    policyButton: true,
    policyText: 'Cookie Policy',
    policyURL: 'http://www.colfnoproblem.com/cookie-policy',
    autoEnable: true,
    acceptOnContinue: false,
    acceptOnScroll: true,
    acceptAnyClick: true,
    expireDays: 365,
    renewOnVisit: false,
    forceShow: false,
    effect: 'slide',
    element: 'body',
    append: false,
    fixed: true,
    bottom: true,
    zindex: '1000',
    domain: 'domain'
  });
});

window.jQuery = $;
window.$ = $;
global.$ = $;
global.jQuery = $;