class window.Sidebar

  @init: ->
    $(document).on('click', '.sidebar-toggle', this.toggle)
    $(document).on('click', '#sidebar-exit-on-click', this.toggle)
    $(window).on('resize', this.check_media)

  @toggle: (e) ->
    e.preventDefault()
    if (!$('#sidebar').hasClass('active'))
      link = e.currentTarget
      $('#sidebar > div').hide()
      $('#sidebar > #'+ $(link).attr('data-sidebar-section')).show()
    $('#sidebar').toggleClass('active')
    $('#sidebar-exit-on-click').toggleClass('active')

  @check_media: ->
    if (matchMedia(Foundation.media_queries['medium-up']))
      $('#sidebar > div').removeAttr('style')

  # This function assures that the advanced search remains open
  # for tablets/mobile when we are playing with the filters
  # (it is required since we introduced instant search otherwise, after selecting the first filter the sidebar would close)
  @auto_open_advanced_search: ->
    link = $('*[data-sidebar-section=sidebar-search]')
    if link && link.is(":visible") && Params.advanced_search
      $('#sidebar > div').hide()
      $('#sidebar > #'+ link.attr('data-sidebar-section')).show()
      $('#sidebar').toggleClass('active')
      $('#sidebar-exit-on-click').toggleClass('active')

Sidebar.init()

