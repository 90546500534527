import Swal from "sweetalert2"

class window.Toolbar

  @setup: ->
    action_table = $('.action_table') # container for checkboxes

    updateToolbarState = () ->
      if action_table.find('*[data-id] input[type=checkbox]:checked').is('*')
        $('.table-toolbar .bulk').removeAttr('disabled')
        $('.toolbar-buttons .bulk').removeAttr('disabled')
      else
        $('.toolbar-buttons .bulk').attr('disabled', 'disabled')
        $('.table-toolbar .bulk').attr('disabled', 'disabled')

    action_table.on 'change', '*[data-id] input[type=checkbox]', () ->
      updateToolbarState()
      if (!$(this).prop('checked') && ($(this).data('action') != 'checkbox-toggle'))
        deselectCheckboxToggle()

    updateToolbarState()

    deselectCheckboxToggle = () ->
      action_table.find('input[type=checkbox][data-action=checkbox-toggle]').prop('checked', null)

    # Initialize checkbox toggle
    $('input[type=checkbox][data-action=checkbox-toggle]').on 'click', (evt) ->
      list_id = $(this).attr('data-list-id')
      status = $(this).prop('checked') || null
      $("input[type=checkbox][name='#{list_id}[id][]']").prop('checked', status)
      $("input[type=checkbox][data-list-id='#{list_id}']").prop('checked', status)
      updateToolbarState()
      true

    # function to actually perform the action for a button
    submitAction = (target) ->
      list_id = target.attr('data-list-id')
      path = target.attr('data-path')
      method = target.attr('data-method')

      if method == 'modal'  
        ids = []
        $("##{list_id} input[type=checkbox]:checked").each((index, item) ->
          ids.push item.value
        )
        $('#modal').foundation('reveal', 'open', {url: path, data: {ids: ids}})
      else
        form = $("##{list_id}_list_form")
        form_method = if (method == 'delete' || method == "put") then "post" else method
        form.attr('action', path)
        form.attr('method', form_method)
        form_method = form.find('input[name=_method]')
        if (method == 'delete' || method == "put") then form_method.val(method) else form_method.remove()
        form.submit()

    # Initialize toolbar buttons
    $('a.toolbar-button').unbind('click').on 'click', (evt) ->
      evt.preventDefault()
      deselectCheckboxToggle()
      target = $(this)

      if target.attr('disabled') != 'disabled'
        confirmation = target.attr('data-confirm-swal')
        if confirmation
          Swal.fire({
            title:             '',
            text:              confirmation,
            icon:              'warning',
            showCancelButton:  true,
            confirmButtonText: 'OK',
            cancelButtonText:  'Annulla',
          }).then (result) -> 
            submitAction(target);
          .catch (error) ->
            console.log error # returns error: 'it broke'
        else
          submitAction(target)
      return false

$ ->
  Toolbar.setup()
