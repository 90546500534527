class window.BackgroundHeight

  @init: ->
    $(window).on('resize', this.set_height)

  @set_height: ->
    inner_max_height = $(window).height() - $('header').outerHeight() - parseInt($('.inner-canvas').css('padding-bottom'))

    if ($('#sidebar') and inner_max_height < $('#sidebar').outerHeight())
      inner_max_height =  $('#sidebar').outerHeight()

    if (inner_max_height > $('#content').outerHeight())
      $('#content').css('height', inner_max_height+'px')
      $('#main').css('height', inner_max_height+'px')
    else
      inner_max_height = $('#content').outerHeight()

    if ($('#sidebar'))
      $('#sidebar').css('height', inner_max_height+'px')

$ ->
  BackgroundHeight.set_height()

BackgroundHeight.init()
