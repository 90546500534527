# Function to execute js code based on the page you're on
# this is useful in conjuntion with turbolinks, to trigger initialization code
# when the page is refreshed

# eg:
#
# $ ->
#   if Page.is('.clients.index, .clients.show')
#     alert('I am in clients#index or clients#show!')
#     Client.preselectSomething()


class window.Page

  @is: (selector) ->
    return $(selector).length > 0

  @contains: (selector) ->
    return $(selector).length > 0
