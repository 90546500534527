class window.EmployeeEconomics

  @prefix = ""

  constructor: (prefix, prefix_radio) ->
    @prefix = prefix
    @prefix_radio = prefix_radio
    $("##{@prefix}employee_super_minimo").on("keyup", @account_changed)
    $("##{@prefix}employee_level").on("change", @account_changed)
    $("##{@prefix}employee_level").on("change", @toggle_allowance)
    $("##{@prefix}employee_level").on("change", @toggle_allowance_text)
    $("##{@prefix}employee_work_contract").on("change", @account_changed)
    $("##{@prefix}employee_work_contract").on("change", @toggle_half_rest_day)
    $("##{@prefix}employee_work_contract").on("change", @toggle_room_and_board)
    $("##{@prefix}employee_employment_date").on("keyup change", @account_changed)
    $("[id^=#{@prefix}employee_under_six_years_assisted_person]").on("click", @account_changed)
    $("[id^=#{@prefix}employee_more_than_one_assisted_person]").on("click", @account_changed)
    $("[id^=#{@prefix}employee_under_six_years_assisted_person]").on("click", @toggle_allowance_text)
    $("[id^=#{@prefix}employee_more_than_one_assisted_person]").on("click", @toggle_allowance_text)
    
  account_changed: =>
    level = $("##{@prefix}employee_level").val()
    work_contract = $("##{@prefix}employee_work_contract").val()
    employment_date = $("##{@prefix}employee_employment_date").val()
    super_minimo =  $("##{@prefix}employee_super_minimo").val()
    under_six_years_assisted_person = $("input[name='#{@prefix_radio}[under_six_years_assisted_person]']:checked").val()
    more_than_one_assisted_person = $("input[name='#{@prefix_radio}[more_than_one_assisted_person]']:checked").val()

    @update_employee_economics(level, work_contract, employment_date, super_minimo, under_six_years_assisted_person, more_than_one_assisted_person)

  update_employee_economics: (level, work_contract, employment_date, super_minimo, under_six_years_assisted_person, more_than_one_assisted_person) =>
    $("##{@prefix}employee_hourly_wage").html("<i class='fa fa-refresh fa-spin'></i>")
    $("##{@prefix}employee_minimum_wage").html("<i class='fa fa-refresh fa-spin'></i>")
    $("##{@prefix}employee_bonus_wage").html("<i class='fa fa-refresh fa-spin'></i>")

    response = $.ajax "/employees/ajax_update_economics",
      data: {employee: {level: level, work_contract: work_contract, employment_date: employment_date, super_minimo: super_minimo, under_six_years_assisted_person: under_six_years_assisted_person, more_than_one_assisted_person: more_than_one_assisted_person}}
      dataType: "json"

    response.fail (data) =>
      @refresh_data("0,00", "0,00", "0,00")
    response.done (data) =>
      @refresh_data(data.minimum_wage, data.bonus_wage, data.hourly_wage)

  refresh_data: (minimum_wage, bonus_wage, hourly_wage) =>
    $("#employee_hourly_wage").text(hourly_wage)
    $("#employee_minimum_wage").text(minimum_wage)
    $("#employee_bonus_wage").text(bonus_wage)

  toggle_half_rest_day: ->
    if this.value == "by_the_hour"
      $("fieldset#half_rest_day").hide()
    else
      $("fieldset#half_rest_day").show()

  toggle_room_and_board: ->
    if this.value == "by_the_hour"
      $("fieldset#room_and_board").hide()
    else
      $("fieldset#room_and_board").show()
  
  toggle_allowance: ->
    if this.value == "b_super"
      $("#under-six-years-assisted-person").show()
    else
      $("#under-six-years-assisted-person").hide()
    if this.value == "c_super" || this.value == "d_super"
      $("#more-than-one-assisted-person").show()
    else
      $("#more-than-one-assisted-person").hide()

  toggle_allowance_text: =>
    level = $("##{@prefix}employee_level").val()
    under_six_years_assisted_person = $("input[name='#{@prefix_radio}[under_six_years_assisted_person]']:checked").val()
    more_than_one_assisted_person = $("input[name='#{@prefix_radio}[more_than_one_assisted_person]']:checked").val()

    if level == 'b_super' && under_six_years_assisted_person == 'true'
      $("#under-six-years-assisted-person-text").show()
    else
      $("#under-six-years-assisted-person-text").hide()

    if (level == 'c_super' || level == 'd_super') && more_than_one_assisted_person == 'true'
      $("#more-than-one-assisted-person-text").show()
    else
      $("#more-than-one-assisted-person-text").hide()
    

$ ->
  if Page.is(".employees.edit")
    new EmployeeEconomics("", "employee")
  if Page.is(".after_signup")
    new EmployeeEconomics("account_form_", "account_form[employee]")  
