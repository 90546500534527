class window.OptionalAddress

  @init: ->
    $('.optional-address').on('click', @toggle_fieldset)

  @toggle_fieldset: (event) ->
    event.preventDefault()
    name = $(this).data('name')
    $('fieldset#'+name).toggle()

$ ->
  if Page.contains('.optional-address')
    OptionalAddress.init()
