class window.Nav

  @init: ->
    $(document).on('click', '.nav-toggle', this.toggle)
    $(document).on('click', '#nav-exit-on-click', this.toggle)
    $(document).on('click', '.side-nav a', this.close)

  @toggle: (e) ->
    e.preventDefault()
    $('#nav-exit-on-click').toggleClass('active')
    $('.off-canvas').toggleClass('active')

  @close: (e) ->
    if $('.off-canvas').hasClass('active')
      $('.off-canvas').toggleClass('active')
      $('#nav-exit-on-click').toggleClass('active')

Nav.init()
