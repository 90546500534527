# Sends a custom click event to Google tag manager
# This is to track clicks on complex components via JavaScript (eg: mosaic in homepage)
#
# Usage:
#
# in your javascript code/callbacks:
#
#   Gtm.trackCustomEvent("Home", "Click Something")
#
# For normal links, just use data attributes e.g.  data: { gtm_category: 'Homepage', gtm_action: 'Click account', gtm_label: '..'}

class window.Gtm

  @trackCustomEvent: (category, action, label) ->
    try
      dataLayer.push
        'event': 'cnp.event',
        'eventCategory': category,
        'eventAction': action,
        'eventLabel': label
      true
    catch
      console.log(_error)
      null
