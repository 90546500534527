class window.ContributionRate

  @init: ->
    $('#contribution_rate_over_24').on('change', @toggle_from_to)

  @toggle_from_to: ->
    $('#over-24').toggle()

$ ->
  if Page.contains('#contribution_rate_over_24')
    ContributionRate.init()
