class window.Tabs


  @init: ->
    $(window).on('resize', this.checkScrolling)
    $('#tabs-container').on('scroll', this.checkScrolling)

  @setWidth: ->
    tabsContainer = $('#tabs-container')
    if tabsContainer.length > 0
      totalTabsWidth = 1
      totalTabsWidth += $(tab).width() for tab in tabsContainer.children('.tabs').children('dd')
      tabsContainer.children('.tabs').width(totalTabsWidth)

  @checkScrolling: ->
    tabsContainer = $('#tabs-container')
    if tabsContainer.length > 0
      totalTabWidth = parseInt(tabsContainer.children('.tabs').width())
      tabsViewport = parseInt(tabsContainer.width())

      if tabsContainer.scrollLeft() >= (totalTabWidth - tabsViewport)
        tabsContainer.addClass('is-ended')
      else
        tabsContainer.removeClass('is-ended')

  @centerActive: ->
    tabsContainer = $('#tabs-container')
    if tabsContainer.length > 0
      active = tabsContainer.children('.tabs').children('.active')
      if active.length > 0 # there might be no active tabs
        activeLeft = active.offset()['left']
        viewportWidth = tabsContainer.width()
        scroll = activeLeft - (viewportWidth / 2)
        tabsContainer.scrollLeft(scroll)

$ ->
  Tabs.init()
  Tabs.setWidth()
  Tabs.checkScrolling()
  Tabs.centerActive()
