class window.Contact

  @init: ->
    $('.contact-link').on('click', @open_modal)

  @open_modal:(evt) ->
    evt.preventDefault() if !!evt
    $('#contact-modal').foundation('reveal', 'open')
    $('#contact-form').show()
    $('#success-message').hide()

$ ->
  Contact.init()
