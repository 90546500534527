import $ from "jquery"
import "jquery-ui"
import "jquery-ui/ui/widgets/datepicker"
import "jquery-ui/ui/i18n/datepicker-it"

class window.Datepicker
  @initializeDatepickers: ->
    # Apply datepicker on date input fields
    # this is also called when loding modals in ajax
    input = $('input[data-datepicker=true]')
    if input.length > 0 
      input.datepicker($.datepicker.regional[ "it" ])
      
$ ->
  Datepicker.initializeDatepickers()
  
#$(document).on 'add_fields', () -> $("input[data-datepicker=true]").datepicker().attr('type','text');
